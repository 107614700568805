import PropTypes from "prop-types"
import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Import necessary Bulma CSS Framework components.
import "./WhatsOnFilter.library.scss"

// Import component specific styles.
import styles from "./WhatsOnFilter.module.scss"

const WhatsOnFilter = (props) => {
  const outerElement = useRef();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const activeMonth = props.activeMonth.index

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = event => {
    if (!outerElement.current.contains(event.target)) {
      setIsDropdownActive(false)
    }
  };
  const firstMonthIndex = 0
  const lastMonthIndex = props.months.length - 1

  const reduceState = () => {
    const prevMonthIndex = activeMonth - 1
    if (prevMonthIndex < firstMonthIndex) {
      const activeMonthUrl = props.months[activeMonth].slug
      return activeMonthUrl
    } else {
      const url = props.months[prevMonthIndex].slug
      return url
    }
  }

  const increaseState = () => {
    const nextMonthIndex = activeMonth + 1
    if (nextMonthIndex <= lastMonthIndex) {
      const url = props.months[nextMonthIndex].slug
      return url
    } else {
      const activeMonthUrl = props.months[activeMonth].slug
      return activeMonthUrl
    }
  }

  const isMonthVisible = (activeMonth, monthIndex) => {
    const windowLowerEnd = activeMonth - 2
    const windowUpperEnd = activeMonth + 3
    if (monthIndex >= windowLowerEnd && monthIndex <= windowUpperEnd) {
      return true
    } else {
      return false
    }
  }

  const isMonthInNewYear = (index, months) => {
    if (index === 0 || getMonthYear(months[index]) === getMonthYear(months[index - 1])) {
      return false
    } else {
      return true
    }
  }

  const getMonthYear = (month) => month.startDate.substring(0, month.startDate.indexOf("-"));

  return (
    <GridContainer>
      <div className={styles.whatsOnFilter}>
        <div className={styles.whatsOnFilterWrapper}>
          <nav className={styles.pagination + " pagination is-centered"} role="navigation">
            <Link to={`/${reduceState()}`} aria-label="Previous month" className={firstMonthIndex === activeMonth ? styles.hidden : styles.prev + " pagination-previous"}>
              <FaChevronLeft />
            </Link>
            <Link to={`/${increaseState()}`} aria-label="Next month" className={lastMonthIndex === activeMonth ? styles.hidden :styles.next + " pagination-next"}>
              <FaChevronRight />
            </Link>

            <div
              ref={outerElement}
              className={isDropdownActive
                ? styles.dropdownActive + " dropdown is-active"
                : styles.dropdown + " dropdown"}>
              <div className={styles.dropdownTrigger + " dropdown-trigger"}>
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  aria-label="View Whats On month options"
                  onClick={() => setIsDropdownActive(!isDropdownActive)}
                >
                  <span>{props.landingPage ? "Upcoming events" : props.activeMonth.name}</span>
                  <span className={styles.dropdownIcon}></span>
                </button>
              </div>
              <div className={styles.dropdownMenu + " dropdown-menu"} aria-label="dropdown-menu" role="menu">
                <ul className={styles.dropdownContent + " dropdown-content"}>
                  { props.months.map((month, index, months) => {
                    return (
                      <React.Fragment key={index + month}>
                        { isMonthInNewYear(index, months) &&
                          <li className={styles.dropdownDivider}>
                            <hr className="dropdown-divider" />
                            <p>{ getMonthYear(month) }</p>
                            <hr className="dropdown-divider" />
                          </li>
                        }
                        <li
                          key={month + index}
                          className={styles.monthActive}
                        >
                          <Link to={"/" + month.slug}
                            className={props.activeMonth.index === index && props.landingPage === false
                              ? styles.paginationLink + " is-current pagination-link " + styles.active
                              : styles.paginationLink + " pagination-link"
                            }
                            onClick={() => setIsDropdownActive(false)}
                            aria-label={'Navigate to ' + month.name + ' event listing'}
                          >
                            {month.name}
                          </Link>
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
              </div>
            </div>

            <ul className={styles.paginationList + " pagination-list"}>
              { props.months.map((month, index) => {
                const monthIndex = index
                return (
                  <li
                    key={month + index}
                    className={isMonthVisible(activeMonth, monthIndex) === true
                      ? styles.monthActive
                      : styles.monthNotActive}
                  >
                    <Link to={"/" + month.slug}
                      className={props.activeMonth.index === month.index && props.landingPage === false
                        ? styles.paginationLink + " is-current pagination-link " + styles.active
                        : styles.paginationLink + " pagination-link"}
                      aria-label={'Navigate to ' + month.name + ' event listing'}
                    >
                      {month.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </div>
    </GridContainer>
  )
}

WhatsOnFilter.propTypes = {
  activeMonth: PropTypes.object,
  months: PropTypes.array,
  landingPage: PropTypes.bool,
}

WhatsOnFilter.defaultProps = {
  activeMonth: {},
  months: [],
  landingPage: false,
}

export default WhatsOnFilter
