import { graphql } from "gatsby"
import React from "react"
import WhatsOnFilter from "../components/WhatsOnFilter/WhatsOnFilter"
import Layout from "../components/layout/Layout/Layout"
import WhatsOn from "../components/WhatsOn/WhatsOn"
import GridContainer from "../components/layout/GridContainer/GridContainer"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"

export const query = graphql`
  query($startDate: Date!) {
    datoCmsSiteConfig {
      whatsOnNoResultsText
      whatsOnUpcomingEventsPageQuantity
    }
    whatsOn: allDatoCmsEvent(
      filter: { eventDate: { gte: $startDate } }
      sort: { order: ASC, fields: [eventDate, startTime] }
    ) {
      ...WhatsOn
    }
  }
`

const WhatsOnPage = props => {
  return (
    <Layout
      metaTitle={"What's on: Upcoming events"}
      metaDescription={"List of all upcoming events in Selsey."}
      metaImage={null}
      twitterCard={null}
      pathname={props.uri}
    >
      <HeroBanner title="What's On: Upcoming Events" />
      <GridContainer>
        <Breadcrumb url={props.uri} />
        <WhatsOnFilter
          activeMonth={{
            name: props.pageContext.monthName,
            index: props.pageContext.monthIndex,
          }}
          months={props.pageContext.months}
          landingPage={true}
        />
        <WhatsOn
          events={props.data.whatsOn.nodes.slice(
            0,
            props.data.datoCmsSiteConfig.whatsOnUpcomingEventsPageQuantity
          )}
          message={
            props.data.datoCmsSiteConfig.whatsOnNoResultsText
              ? props.data.datoCmsSiteConfig.whatsOnNoResultsText
              : undefined
          }
        />
        {props.data.whatsOn.nodes.slice(
          0,
          props.data.datoCmsSiteConfig.whatsOnUpcomingEventsPageQuantity
        ).length > 0 && (
          <WhatsOnFilter
            activeMonth={{
              name: props.pageContext.monthName,
              index: props.pageContext.monthIndex,
            }}
            months={props.pageContext.months}
            landingPage={true}
          />
        )}
      </GridContainer>
    </Layout>
  )
}

export default WhatsOnPage
